import * as React from "react"
import styled from "styled-components"

import { Link as GatsbyLink } from "gatsby"

import Link from "@material-ui/core/Link"
// @ts-ignore
import logo from '../assets/logo.svg'

import { color, position, PositionProps, ColorProps } from "styled-system"

const Wrapper = styled.div`
  display: flex;
  flex: 100vw;

  height: 70px;
  line-height: 70px;
  width: auto;
  @media screen and (min-width: 600px) {
    height: 120px;
    line-height: 120px;
  }
  ${color}
  a {
    background-image: none;
  }
  ${position}
`

const Logo = styled.img`
  margin-bottom: 0;
  height: 70px;
  width: auto;
  @media screen and (min-width: 600px) {
    height: 120px;
  }
`

const Header = ({ bg, color, position }: PositionProps & ColorProps) => {
  return (
    <Wrapper {...{ bg, color, position }}>
      <Link component={GatsbyLink} to="/">
        <Logo src={logo} alt="Pharmille logo" />
      </Link>
    </Wrapper>
  )
}

export default Header
