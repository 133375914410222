import * as React from "react"
import styled from "styled-components"

import Link from "@material-ui/core/Link"
import { Link as GatsbyLink } from "gatsby"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 120px;
  background-color: black;
  color: white;
  font-size: 15px;
  line-height: 15px;
  a {
    color: white;
    text-shadow: none;
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0) 1px,
      #fff 1px,
      #fff 2px,
      rgba(0, 0, 0, 0) 2px
    );
    &:active,
    &:hover {
      background-image: none;
    }
  }
`

const List = styled.ul`
  list-style: none;
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 0;
  > li {
    margin: 0 0 0 16px;
  }
`

const Footer = () => {
  return (
    <Wrapper>
      <List>
        <li>
          <Link component={GatsbyLink} to="/legal">
            Mentions Légales
          </Link>
        </li>
        <li>
          <Link component={GatsbyLink} to="/data-privacy">
            Politique de confidentialité
          </Link>
        </li>
      </List>
    </Wrapper>
  )
}

export default Footer
