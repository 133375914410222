import * as React from "react"

import { createMuiTheme } from "@material-ui/core/styles"
import { ThemeProvider } from "@material-ui/styles"

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#39677c",
      main: "#08415c",
      dark: "#052d40",
    },
    secondary: {
      light: "#fb9b6f",
      main: "#fa824c",
      dark: "#af5b35",
    },
  },
})

const Theme = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default Theme
